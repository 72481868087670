.app-config {
	&__image {
		max-width: inherit;
	}
}

.image {
	&__content {
		max-width: calc(100% - 40px);
	}
}
