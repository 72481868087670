@import "variable";
@import "mixins";

$barlow-font-path: "fonts/barlow";
$rubik-font-path: "fonts/rubik";

/* barlow-regular - latin */
@font-face {
	font-family: 'Barlow';
	font-style: normal;
	font-weight: 400;
	src: url('#{$barlow-font-path}/barlow-v4-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
	src: local('Barlow Regular'), local('Barlow-Regular'),
		url('#{$barlow-font-path}/barlow-v4-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'),
		url('#{$barlow-font-path}/barlow-v4-latin-ext_latin-regular.woff2') format('woff2'),
		url('#{$barlow-font-path}/barlow-v4-latin-ext_latin-regular.woff') format('woff'),
		url('#{$barlow-font-path}/barlow-v4-latin-ext_latin-regular.ttf') format('truetype'),
		url('#{$barlow-font-path}/barlow-v4-latin-ext_latin-regular.svg#Barlow') format('svg'); /* Legacy iOS */
}

/* rubik-regular - latin-ext_latin */
@font-face {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 400;
	src: url('#{$rubik-font-path}/rubik-v9-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
	src: local('Rubik'), local('Rubik-Regular'),
		url('#{$rubik-font-path}/rubik-v9-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'),
		url('#{$rubik-font-path}/rubik-v9-latin-ext_latin-regular.woff2') format('woff2'),
		url('#{$rubik-font-path}/rubik-v9-latin-ext_latin-regular.woff') format('woff'),
		url('#{$rubik-font-path}/rubik-v9-latin-ext_latin-regular.ttf') format('truetype'),
		url('#{$rubik-font-path}/rubik-v9-latin-ext_latin-regular.svg#Rubik') format('svg'); /* Legacy iOS */
}

.register {
	&-dialog {
		label {
			font-family: 'Rubik';
			line-height: 1.5;
			font-size: 0.6rem;
			font-weight: 400;
			color: $app-color-150;
		}
		input,
		input[type="text"],
		select {
			font-family: 'Rubik';
			font-size: 0.75rem;
			font-weight: 500;
			line-height: 1;
			border: none;
			border-radius: 0;
			border-bottom-width: 0.75px;
			border-bottom-style: solid;
			border-bottom-color: $app-color-250;
			padding: 0;
			height: auto;

			&.form-control.is-invalid {
				font-family: 'Rubik';
				font-size: 0.75rem;
				font-weight: 500;
				line-height: 1;
				border-color: $app-color-300;
			}
			/* Change the white to any color ;) */
			&:-webkit-autofill,
			&:-webkit-autofill:hover,
			&:-webkit-autofill:focus,
			&:-webkit-autofill:active {
				-webkit-box-shadow: 0 0 0 30px white inset !important;
			}
		}
		button {
			font-family: 'Barlow';
			font-weight: 600;
			text-transform: uppercase;
			letter-spacing: 2px;
			font-size: 0.75rem;
			line-height: 1.8;
			margin-bottom: 10px;

			&::last-of-type {
				margin-bottom: 0px;
			}
		}
		a {
			font-family: 'Rubik';
			font-size: 0.6rem;
			font-weight: 700;
			line-height: 1.5;
			color: $app-color-400;
			text-decoration: underline;

		}

		.additional-text {
			display: flex;
			align-items: center;
			font-family: 'Rubik';
			font-size: 0.5rem;
			font-weight: 400;
			line-height: 1.5;
			color: $app-color-350;
			height: 100%;
		}
	}
	&-header,
	&-footer,
	&-body {
		background: #fff;
		padding: 2.5rem;
	}
	&-header {
		border-bottom: 0.75px solid $app-color-200;
	}
	&-footer {
		border-top: 0.75px solid $app-color-200;
		justify-content: center;
	}
	&-subheader {
		@include display-flex();
		align-items: baseline;
		justify-content: space-between;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
	&-title,
	&-subtitle {
		font-family: 'Barlow';
		font-weight: 600;
		text-transform: uppercase;
	}
	&-title {
		letter-spacing: 3px;
		line-height: 2;
		font-size: 1rem;
		color: $app-color-100;
	}
	&-subtitle {
		letter-spacing: 1px;
		line-height: 1.5;
		font-size: 0.6rem;
		text-decoration: underline;
		color: $app-color-150;
	}
	&-button-container {
		flex-grow: 1;
	}
	&-button {
		width: 100%;
	}
}
