.menu {
	@media (max-height: 640px) {
		flex-direction: column;
	}
	.menu__card,
	.menuElement__card {
		display: flex;
		flex: 1 1 auto;
		height: calc(100vh - 350px);

		@media (max-height: 640px) {
			height: 375px;
		}
		.menu__button {
			.button__icon {
				font-size: 5em !important;
				font-weight: 700;
			}
			.button__text {
				font-size: 2em !important;
			}
		}
		.rbt {
			&-aux {
				right: 10px;
			}
			&-token {
				&-removeable {
					width: auto;
				}
			}
		}
	}
	.menuElement__card {
		height: calc(100vh - 400px);

		@media (max-height: 640px) {
			height: auto;
		}
	}
}

.menu-position-dropdown-container {
	height: calc(100vh - 459px);

	@media screen and (max-height: 640px)  {
		height: 300px;
	}
}

.md-no-padding {
	@media screen and (max-width: 992px) {
		padding: 0;
	}
}
