// Sidebar Base

.app-sidebar {
  width: $app-sidebar-width;
  display: flex;
  z-index: 11;
  overflow: hidden;
  min-width: $app-sidebar-width;
  position: relative;
  flex: 0 0 $app-sidebar-width;
  margin-top: -$app-header-height;
  padding-top: $app-header-height;
  transition: all 0.2s;

  .app-sidebar__inner {
    padding: 4px ($layout-spacer-sm) $layout-spacer-sm;
    min-height: 100%;
    min-width: 100%;

    overflow-y: auto;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #e5e5e5;
      top: 100px;
      margin: 10px 0;
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      top: 60px;
      height: 80%;
      width: 10px;
      border-radius: 10px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #898989;
      border-radius: 10px;
    }

    .vertical-nav-menu {
      position: relative;

      .metismenu-container {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;

        &--visible {
          padding: 0.5em 0 0 2rem;
        }

        .metismenu-item {
          margin: 0;
          padding: 0;
          position: relative;

          .metismenu-link {
            display: flex;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .metismenu-label {
          position: relative;
          left: 25px;
        }

        .metismenu-link {
          pointer-events: all;
          opacity: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &--visible {
            height: 2.5rem;
          }

          &:hover {
            background: #e0f3ff;
            text-decoration: none;
          }

          &--active {
            font-weight: bold;
            color: #007bff !important;
            background: #e0f3ff;
          }

          &--disabled {
            opacity: 0.5;
            pointer-events: none;
          }

          &__icon {
            text-align: center;
            width: 34px;
            height: 34px;
            line-height: 34px;
            position: absolute;
            left: 5px;
            top: 50%;
            margin-top: -17px;
            font-size: 1.5rem;
            // opacity: 0.3;
            transition: color 300ms;

            &--right {
              transition: transform 300ms;
              left: auto;
              right: 0;
              font-size: 27px;
              font-weight: 500;
              padding-top: 2px;
            }

            &--lock {
              font-size: 18px;
              font-weight: 500;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }

  .scrollbar-container {
    z-index: 15;
    width: 100%;
  }

  .app-sidebar-bg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0.05;
    background-size: cover;
    z-index: 10;
  }

  .app-header__logo {
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    z-index: 11;
  }

  &.sidebar-shadow {
    box-shadow: 7px 0 60px rgba(0, 0, 0, 0.05);
  }
}

.app-sidebar__heading {
  text-transform: uppercase;
  font-size: $font-size-xs;
  margin: ($layout-spacer-x / 2) 0;
  font-weight: bold;
  color: $primary;
  white-space: nowrap;
  position: relative;
}

.sidebar-mobile-overlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background: #333;
  opacity: 0.6;
  left: 0;
  top: 0;
  z-index: 7;
}

// Sidebar Components

@import "modifiers/navmenu-vertical";

// Sidebar Modifiers

@import "themes/sidebar-light";
@import "themes/sidebar-dark";
//@import "themes/sidebar-inverted";

@import "modifiers/fixed-sidebar";
@import "modifiers/closed-sidebar";
