/**
 * Extra class applied to VirtualScroll through className prop
 */
.rst__virtualScrollOverride {
	overflow: auto !important;
}

.ReactVirtualized__Grid__innerScrollContainer {
	overflow: visible !important;
}

.rst__rtl .ReactVirtualized__Grid__innerScrollContainer {
	direction: rtl;
}

.ReactVirtualized__Grid {
	outline: none;
}

.rst__node {
	min-width: 100%;
	white-space: nowrap;
	position: relative;
	text-align: left;
}

.rst__node.rst__rtl {
	text-align: right;
}

.rst__nodeContent {
	position: absolute;
	top: 0;
	bottom: 0;
}

/* ==========================================================================
   Scaffold

    Line-overlaid blocks used for showing the tree structure
   ========================================================================== */
.rst__lineBlock,
.rst__absoluteLineBlock {
	height: 100%;
	position: relative;
	display: inline-block;
}

.rst__absoluteLineBlock {
	position: absolute;
	top: 0;
}

.rst__lineHalfHorizontalRight::before,
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
	position: absolute;
	content: '';
	background-color: black;
}

/**
 * +-----+
 * |     |
 * |  +--+
 * |     |
 * +-----+
 */
.rst__lineHalfHorizontalRight::before {
	height: 0.06rem;
	top: 50%;
	right: 0;
	width: 50%;
}

@-moz-document url-prefix() {
	@media screen and (max-width: 1500px) {
		.rst__lineHalfHorizontalRight::before {
			height: 0.046rem;
			top: 50%;
			right: 0;
			width: 50%;
		}
	}
}

@-moz-document url-prefix() {
	@media screen and (min-width: 1950px) {
		.rst__lineHalfHorizontalRight::before {
			height: 0.07rem;
			top: 50%;
			right: 0;
			width: 50%;
		}
	}
}

.rst__rtl.rst__lineHalfHorizontalRight::before {
	left: 0;
	right: initial;
}

.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
	width: 0.05rem;
	left: 50%;
	top: 0;
	height: 100%;
}

@-moz-document url-prefix() {
	@media screen and (min-width: 2000px) {
		.rst__lineFullVertical::after,
		.rst__lineHalfVerticalTop::after,
		.rst__lineHalfVerticalBottom::after {
			width: 0.07rem;
			left: 50%;
			top: 0;
			height: 100%;
		}
	}
}

.rst__rtl.rst__lineFullVertical::after,
.rst__rtl.rst__lineHalfVerticalTop::after,
.rst__rtl.rst__lineHalfVerticalBottom::after {
	right: 50%;
	left: initial;
}

.rst__lineHalfVerticalTop::after {
	height: 50%;
}

.rst__lineHalfVerticalBottom::after {
	top: auto;
	bottom: 0;
	height: 50%;
}

/* Highlight line for pointing to dragged row destination
   ========================================================================== */
.rst__highlightLineVertical {
	z-index: 3;
}
.rst__highlightLineVertical::before {
	position: absolute;
	content: '';
	background-color: $primary;
	width: 8px;
	margin-left: -4px;
	left: 50%;
	top: 0;
	height: 100%;
}

.rst__rtl.rst__highlightLineVertical::before {
	margin-left: initial;
	margin-right: -4px;
	left: initial;
	right: 50%;
}

@keyframes arrow-pulse {
	0% {
		transform: translate(0, 0);
		opacity: 0;
	}
	30% {
		transform: translate(0, 300%);
		opacity: 1;
	}
	70% {
		transform: translate(0, 700%);
		opacity: 1;
	}
	100% {
		transform: translate(0, 1000%);
		opacity: 0;
	}
}
.rst__highlightLineVertical::after {
	content: '';
	position: absolute;
	height: 0;
	margin-left: -4px;
	left: 50%;
	top: 0;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 4px solid white;
	animation: arrow-pulse 1s infinite linear both;
}

.rst__rtl.rst__highlightLineVertical::after {
	margin-left: initial;
	margin-right: -4px;
	right: 50%;
	left: initial;
}

/**
 * +-----+
 * |     |
 * |  +--+
 * |  |  |
 * +--+--+
 */
.rst__highlightTopLeftCorner::before {
	z-index: 3;
	content: '';
	position: absolute;
	border-top: solid 8px $primary;
	border-left: solid 8px $primary;
	box-sizing: border-box;
	height: calc(50% + 4px);
	top: 50%;
	margin-top: -4px;
	right: 0;
	width: calc(50% + 4px);
}

.rst__rtl.rst__highlightTopLeftCorner::before {
	border-right: solid 8px $primary;
	border-left: none;
	left: 0;
	right: initial;
}

/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  +->|
 * +-----+
 */
.rst__highlightBottomLeftCorner {
	z-index: 3;
}
.rst__highlightBottomLeftCorner::before {
	content: '';
	position: absolute;
	border-bottom: solid 8px $primary;
	border-left: solid 8px $primary;
	box-sizing: border-box;
	height: calc(100% + 4px);
	top: 0;
	right: 12px;
	width: calc(50% - 8px);
}

.rst__rtl.rst__highlightBottomLeftCorner::before {
	border-right: solid 8px $primary;
	border-left: none;
	left: 12px;
	right: initial;
}

.rst__highlightBottomLeftCorner::after {
	content: '';
	position: absolute;
	height: 0;
	right: 0;
	top: 100%;
	margin-top: -12px;
	border-top: 12px solid transparent;
	border-bottom: 12px solid transparent;
	border-left: 12px solid $primary;
}

.rst__rtl.rst__highlightBottomLeftCorner::after {
	left: 0;
	right: initial;
	border-right: 12px solid $primary;
	border-left: none;
}

.rst__rowWrapper {
	padding: 10px 10px 10px 0;
	height: 100%;
	box-sizing: border-box;
}

.rst__rtl.rst__rowWrapper {
	padding: 10px 0 10px 10px;
}

.rst__row {
	height: 100%;
	white-space: nowrap;
	display: flex;
}
.rst__row > * {
	box-sizing: border-box;
}

/**
 * The outline of where the element will go if dropped, displayed while dragging
 */
.rst__rowLandingPad,
.rst__rowCancelPad {
	border: none !important;
	box-shadow: none !important;
	outline: none !important;
}
.rst__rowLandingPad > *,
.rst__rowCancelPad > * {
	opacity: 0 !important;
}
.rst__rowLandingPad::before,
.rst__rowCancelPad::before {
	background-color: lightblue;
	border: 1px dashed $white;
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
}

/**
 * Alternate appearance of the landing pad when the dragged location is invalid
 */
.rst__rowCancelPad::before {
	background-color: #e6a8ad;
}

/**
 * Nodes matching the search conditions are highlighted
 */
.rst__rowSearchMatch {
	outline: solid 3px #0080ff;
}

/**
 * The node that matches the search conditions and is currently focused
 */
.rst__rowSearchFocus {
	outline: solid 3px #fc6421;
}

.rst__rowContents,
.rst__rowLabel,
.rst__rowToolbar,
.rst__moveHandle,
.rst__toolbarButton {
	display: inline-block;
	vertical-align: middle;
}

.rst__rowContents {
	position: relative;
	height: 100%;
	border: solid $gray-300 1px;
	border-left: none;
	padding: 0 5px 0 10px;
	border-radius: 2px;
	min-width: 405px;
	flex: 1 0 auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: white;
}

.rst__rtl.rst__rowContents {
	border-right: none;
	border-left: solid $gray-300 1px;
	padding: 0 10px 0 5px;
}

.rst__rowLabel {
	flex: 1 1 auto;
	padding-right: 20px;
}
.rst__rtl.rst__rowLabel {
	padding-left: 20px;
	padding-right: inherit;
}

.rst__rowToolbar {
	flex: 0 1 auto;
	display: flex;
}

.rst__moveHandle,
.rst__loadingHandle {
	height: 100%;
	width: 44px;
	background: $gray-200;
	background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjNEI1MzU1IiBzdHJva2Utd2lkdGg9IjIuOSIgPjxwYXRoIGQ9Ik0xNCAxNS43aDE0LjQiLz48cGF0aCBkPSJNMTQgMjEuNGgxNC40Ii8+PHBhdGggZD0iTTE0IDI3LjFoMTQuNCIvPjwvZz4KPC9zdmc+');
	background-repeat: no-repeat;
	background-position: center;
	background-size: 80%;
	border: solid #aaa 1px;
	cursor: grab;
	border-radius: 1px;
	z-index: 1;
	&--grabbing {
		cursor: grabbing;
	}
}

.rst__moveHandle--draft {
	background-size: 45%;
	background-image: url('data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJwZW5jaWwtYWx0IiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtcGVuY2lsLWFsdCBmYS13LTE2IiByb2xlPSJpbWciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDUxMiA1MTIiPjxwYXRoIGZpbGw9IiM0QjUzNTUiIGQ9Ik00OTcuOSAxNDIuMWwtNDYuMSA0Ni4xYy00LjcgNC43LTEyLjMgNC43LTE3IDBsLTExMS0xMTFjLTQuNy00LjctNC43LTEyLjMgMC0xN2w0Ni4xLTQ2LjFjMTguNy0xOC43IDQ5LjEtMTguNyA2Ny45IDBsNjAuMSA2MC4xYzE4LjggMTguNyAxOC44IDQ5LjEgMCA2Ny45ek0yODQuMiA5OS44TDIxLjYgMzYyLjQuNCA0ODMuOWMtMi45IDE2LjQgMTEuNCAzMC42IDI3LjggMjcuOGwxMjEuNS0yMS4zIDI2Mi42LTI2Mi42YzQuNy00LjcgNC43LTEyLjMgMC0xN2wtMTExLTExMWMtNC44LTQuNy0xMi40LTQuNy0xNy4xIDB6TTEyNC4xIDMzOS45Yy01LjUtNS41LTUuNS0xNC4zIDAtMTkuOGwxNTQtMTU0YzUuNS01LjUgMTQuMy01LjUgMTkuOCAwczUuNSAxNC4zIDAgMTkuOGwtMTU0IDE1NGMtNS41IDUuNS0xNC4zIDUuNS0xOS44IDB6TTg4IDQyNGg0OHYzNi4zbC02NC41IDExLjMtMzEuMS0zMS4xTDUxLjcgMzc2SDg4djQ4eiI+PC9wYXRoPjwvc3ZnPg==');
}

.rst__moveHandle--confirmed {
	background-size: 45%;
	background-image: url('data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJ0aHVtYnMtdXAiIGNsYXNzPSJzdmctaW5saW5lLS1mYSBmYS10aHVtYnMtdXAgZmEtdy0xNiIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjNEI1MzU1IiBkPSJNMTA0IDIyNEgyNGMtMTMuMjU1IDAtMjQgMTAuNzQ1LTI0IDI0djI0MGMwIDEzLjI1NSAxMC43NDUgMjQgMjQgMjRoODBjMTMuMjU1IDAgMjQtMTAuNzQ1IDI0LTI0VjI0OGMwLTEzLjI1NS0xMC43NDUtMjQtMjQtMjR6TTY0IDQ3MmMtMTMuMjU1IDAtMjQtMTAuNzQ1LTI0LTI0czEwLjc0NS0yNCAyNC0yNCAyNCAxMC43NDUgMjQgMjQtMTAuNzQ1IDI0LTI0IDI0ek0zODQgODEuNDUyYzAgNDIuNDE2LTI1Ljk3IDY2LjIwOC0zMy4yNzcgOTQuNTQ4aDEwMS43MjNjMzMuMzk3IDAgNTkuMzk3IDI3Ljc0NiA1OS41NTMgNTguMDk4LjA4NCAxNy45MzgtNy41NDYgMzcuMjQ5LTE5LjQzOSA0OS4xOTdsLS4xMS4xMWM5LjgzNiAyMy4zMzcgOC4yMzcgNTYuMDM3LTkuMzA4IDc5LjQ2OSA4LjY4MSAyNS44OTUtLjA2OSA1Ny43MDQtMTYuMzgyIDc0Ljc1NyA0LjI5OCAxNy41OTggMi4yNDQgMzIuNTc1LTYuMTQ4IDQ0LjYzMkM0NDAuMjAyIDUxMS41ODcgMzg5LjYxNiA1MTIgMzQ2LjgzOSA1MTJsLTIuODQ1LS4wMDFjLTQ4LjI4Ny0uMDE3LTg3LjgwNi0xNy41OTgtMTE5LjU2LTMxLjcyNS0xNS45NTctNy4wOTktMzYuODIxLTE1Ljg4Ny01Mi42NTEtMTYuMTc4LTYuNTQtLjEyLTExLjc4My01LjQ1Ny0xMS43ODMtMTEuOTk4di0yMTMuNzdjMC0zLjIgMS4yODItNi4yNzEgMy41NTgtOC41MjEgMzkuNjE0LTM5LjE0NCA1Ni42NDgtODAuNTg3IDg5LjExNy0xMTMuMTExIDE0LjgwNC0xNC44MzIgMjAuMTg4LTM3LjIzNiAyNS4zOTMtNTguOTAyQzI4Mi41MTUgMzkuMjkzIDI5MS44MTcgMCAzMTIgMGMyNCAwIDcyIDggNzIgODEuNDUyeiI+PC9wYXRoPjwvc3ZnPg==');
}
.rst__moveHandle--toPublish {
	background-size: 45%;
	background-image: url('data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJmaWxlLWV4cG9ydCIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLWZpbGUtZXhwb3J0IGZhLXctMTgiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTc2IDUxMiI+PHBhdGggZmlsbD0iIzRCNTM1NSIgZD0iTTM4NCAxMjEuOWMwLTYuMy0yLjUtMTIuNC03LTE2LjlMMjc5LjEgN2MtNC41LTQuNS0xMC42LTctMTctN0gyNTZ2MTI4aDEyOHpNNTcxIDMwOGwtOTUuNy05Ni40Yy0xMC4xLTEwLjEtMjcuNC0zLTI3LjQgMTEuM1YyODhoLTY0djY0aDY0djY1LjJjMCAxNC4zIDE3LjMgMjEuNCAyNy40IDExLjNMNTcxIDMzMmM2LjYtNi42IDYuNi0xNy40IDAtMjR6bS0zNzkgMjh2LTMyYzAtOC44IDcuMi0xNiAxNi0xNmgxNzZWMTYwSDI0OGMtMTMuMiAwLTI0LTEwLjgtMjQtMjRWMEgyNEMxMC43IDAgMCAxMC43IDAgMjR2NDY0YzAgMTMuMyAxMC43IDI0IDI0IDI0aDMzNmMxMy4zIDAgMjQtMTAuNyAyNC0yNFYzNTJIMjA4Yy04LjggMC0xNi03LjItMTYtMTZ6Ij48L3BhdGg+PC9zdmc+');
}
.rst__moveHandle--published {
	background-size: 45%;
	background-image: url('data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJjaGVjay1jaXJjbGUiIGNsYXNzPSJzdmctaW5saW5lLS1mYSBmYS1jaGVjay1jaXJjbGUgZmEtdy0xNiIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjNEI1MzU1IiBkPSJNNTA0IDI1NmMwIDEzNi45NjctMTExLjAzMyAyNDgtMjQ4IDI0OFM4IDM5Mi45NjcgOCAyNTYgMTE5LjAzMyA4IDI1NiA4czI0OCAxMTEuMDMzIDI0OCAyNDh6TTIyNy4zMTQgMzg3LjMxNGwxODQtMTg0YzYuMjQ4LTYuMjQ4IDYuMjQ4LTE2LjM3OSAwLTIyLjYyN2wtMjIuNjI3LTIyLjYyN2MtNi4yNDgtNi4yNDktMTYuMzc5LTYuMjQ5LTIyLjYyOCAwTDIxNiAzMDguMTE4bC03MC4wNTktNzAuMDU5Yy02LjI0OC02LjI0OC0xNi4zNzktNi4yNDgtMjIuNjI4IDBsLTIyLjYyNyAyMi42MjdjLTYuMjQ4IDYuMjQ4LTYuMjQ4IDE2LjM3OSAwIDIyLjYyN2wxMDQgMTA0YzYuMjQ5IDYuMjQ5IDE2LjM3OSA2LjI0OSAyMi42MjguMDAxeiI+PC9wYXRoPjwvc3ZnPg==');
}
.rst__moveHandle--withdrawed {
	background-size: 45%;
	background-image: url('data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJhcnJvdy1jaXJjbGUtbGVmdCIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLWFycm93LWNpcmNsZS1sZWZ0IGZhLXctMTYiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHBhdGggZmlsbD0iIzRCNTM1NSIgZD0iTTI1NiA1MDRDMTE5IDUwNCA4IDM5MyA4IDI1NlMxMTkgOCAyNTYgOHMyNDggMTExIDI0OCAyNDgtMTExIDI0OC0yNDggMjQ4em0yOC45LTE0My42TDIwOS40IDI4OEgzOTJjMTMuMyAwIDI0LTEwLjcgMjQtMjR2LTE2YzAtMTMuMy0xMC43LTI0LTI0LTI0SDIwOS40bDc1LjUtNzIuNGM5LjctOS4zIDkuOS0yNC44LjQtMzQuM2wtMTEtMTAuOWMtOS40LTkuNC0yNC42LTkuNC0zMy45IDBMMTA3LjcgMjM5Yy05LjQgOS40LTkuNCAyNC42IDAgMzMuOWwxMzIuNyAxMzIuN2M5LjQgOS40IDI0LjYgOS40IDMzLjkgMGwxMS0xMC45YzkuNS05LjUgOS4zLTI1LS40LTM0LjN6Ij48L3BhdGg+PC9zdmc+');
}
.rst__moveHandle--archived {
	background-size: 45%;
	background-image: url('data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJhcmNoaXZlIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtYXJjaGl2ZSBmYS13LTE2IiByb2xlPSJpbWciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDUxMiA1MTIiPjxwYXRoIGZpbGw9IiM0QjUzNTUiIGQ9Ik0zMiA0NDhjMCAxNy43IDE0LjMgMzIgMzIgMzJoMzg0YzE3LjcgMCAzMi0xNC4zIDMyLTMyVjE2MEgzMnYyODh6bTE2MC0yMTJjMC02LjYgNS40LTEyIDEyLTEyaDEwNGM2LjYgMCAxMiA1LjQgMTIgMTJ2OGMwIDYuNi01LjQgMTItMTIgMTJIMjA0Yy02LjYgMC0xMi01LjQtMTItMTJ2LTh6TTQ4MCAzMkgzMkMxNC4zIDMyIDAgNDYuMyAwIDY0djQ4YzAgOC44IDcuMiAxNiAxNiAxNmg0ODBjOC44IDAgMTYtNy4yIDE2LTE2VjY0YzAtMTcuNy0xNC4zLTMyLTMyLTMyeiI+PC9wYXRoPjwvc3ZnPg==');
}

.rst__moveHandle.rst__moveHandleDisabled {
	background-size: 30%;
	background-image: url('data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJsb2NrIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtbG9jayBmYS13LTE0IiByb2xlPSJpbWciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDQ0OCA1MTIiPjxwYXRoIGZpbGw9IiM0QjUzNTUiIGQ9Ik00MDAgMjI0aC0yNHYtNzJDMzc2IDY4LjIgMzA3LjggMCAyMjQgMFM3MiA2OC4yIDcyIDE1MnY3Mkg0OGMtMjYuNSAwLTQ4IDIxLjUtNDggNDh2MTkyYzAgMjYuNSAyMS41IDQ4IDQ4IDQ4aDM1MmMyNi41IDAgNDgtMjEuNSA0OC00OFYyNzJjMC0yNi41LTIxLjUtNDgtNDgtNDh6bS0xMDQgMEgxNTJ2LTcyYzAtMzkuNyAzMi4zLTcyIDcyLTcyczcyIDMyLjMgNzIgNzJ2NzJ6Ij48L3BhdGg+PC9zdmc+');
}

.rst__loadingHandle {
	cursor: default;
	background: $gray-200;
}

@keyframes pointFade {
	0%,
	19.999%,
	100% {
		opacity: 0;
	}
	20% {
		opacity: 1;
	}
}

.rst__loadingCircle {
	width: 80%;
	height: 80%;
	margin: 10%;
	position: relative;
}

.rst__loadingCirclePoint {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
}

.rst__labelCont {
	display: flex;
}

.rst__labelText {
	flex: 1;
}

.rst__labelIcon {
	margin: 2px 0 0 5px;
	background: #ddd;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		width: 12px;
	}
}

.rst__checkbox-input {
	input {
		display: none;

		&:checked + .rst__checkbox-sign {
			background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjIyIj48cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+PHBhdGggZmlsbD0iIzU0NWNkOCIgZD0iTTE5IDNINWMtMS4xMSAwLTIgLjktMiAydjE0YzAgMS4xLjg5IDIgMiAyaDE0YzEuMTEgMCAyLS45IDItMlY1YzAtMS4xLS44OS0yLTItMnptLTkgMTRsLTUtNSAxLjQxLTEuNDFMMTAgMTQuMTdsNy41OS03LjU5TDE5IDhsLTkgOXoiLz48L3N2Zz4=');
		}

		+ .rst__checkbox-sign {
			width: 22px;
			height: 22px;
			cursor: pointer;
			background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjIyIj48cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+PHBhdGggZmlsbD0iI2FhYSIgZD0iTTE5IDV2MTRINVY1aDE0bTAtMkg1Yy0xLjEgMC0yIC45LTIgMnYxNGMwIDEuMS45IDIgMiAyaDE0YzEuMSAwIDItLjkgMi0yVjVjMC0xLjEtLjktMi0yLTJ6Ii8+PC9zdmc+');
		}
	}
}

.rst__rtl.rst__loadingCirclePoint {
	right: 0;
	left: initial;
}

.rst__loadingCirclePoint::before {
	content: '';
	display: block;
	margin: 0 auto;
	width: 11%;
	height: 30%;
	background-color: $white;
	border-radius: 30%;
	animation: pointFade 800ms infinite ease-in-out both;
}
.rst__loadingCirclePoint:nth-of-type(1) {
	transform: rotate(0deg);
}
.rst__loadingCirclePoint:nth-of-type(7) {
	transform: rotate(180deg);
}
.rst__loadingCirclePoint:nth-of-type(1)::before,
.rst__loadingCirclePoint:nth-of-type(7)::before {
	animation-delay: -800ms;
}
.rst__loadingCirclePoint:nth-of-type(2) {
	transform: rotate(30deg);
}
.rst__loadingCirclePoint:nth-of-type(8) {
	transform: rotate(210deg);
}
.rst__loadingCirclePoint:nth-of-type(2)::before,
.rst__loadingCirclePoint:nth-of-type(8)::before {
	animation-delay: -666ms;
}
.rst__loadingCirclePoint:nth-of-type(3) {
	transform: rotate(60deg);
}
.rst__loadingCirclePoint:nth-of-type(9) {
	transform: rotate(240deg);
}
.rst__loadingCirclePoint:nth-of-type(3)::before,
.rst__loadingCirclePoint:nth-of-type(9)::before {
	animation-delay: -533ms;
}
.rst__loadingCirclePoint:nth-of-type(4) {
	transform: rotate(90deg);
}
.rst__loadingCirclePoint:nth-of-type(10) {
	transform: rotate(270deg);
}
.rst__loadingCirclePoint:nth-of-type(4)::before,
.rst__loadingCirclePoint:nth-of-type(10)::before {
	animation-delay: -400ms;
}
.rst__loadingCirclePoint:nth-of-type(5) {
	transform: rotate(120deg);
}
.rst__loadingCirclePoint:nth-of-type(11) {
	transform: rotate(300deg);
}
.rst__loadingCirclePoint:nth-of-type(5)::before,
.rst__loadingCirclePoint:nth-of-type(11)::before {
	animation-delay: -266ms;
}
.rst__loadingCirclePoint:nth-of-type(6) {
	transform: rotate(150deg);
}
.rst__loadingCirclePoint:nth-of-type(12) {
	transform: rotate(330deg);
}
.rst__loadingCirclePoint:nth-of-type(6)::before,
.rst__loadingCirclePoint:nth-of-type(12)::before {
	animation-delay: -133ms;
}
.rst__loadingCirclePoint:nth-of-type(7) {
	transform: rotate(180deg);
}
.rst__loadingCirclePoint:nth-of-type(13) {
	transform: rotate(360deg);
}
.rst__loadingCirclePoint:nth-of-type(7)::before,
.rst__loadingCirclePoint:nth-of-type(13)::before {
	animation-delay: 0ms;
}

.rst__rowTitle {
	font-weight: bold;
}

.rst__rowTitleWithSubtitle {
	font-size: 85%;
	display: block;
	height: 0.8rem;
}

.rst__rowSubtitle {
	font-size: 70%;
	line-height: 1;
}

.rst__collapseButton,
.rst__expandButton {
	appearance: none;
	border: none;
	position: absolute;
	border-radius: 100%;
	box-shadow: 0 0 0 1px #000;
	width: 16px;
	height: 16px;
	padding: 0;
	top: 50%;
	transform: translate(-50%, -50%);
	cursor: pointer;
}
.rst__rtl.rst__collapseButton,
.rst__rtl.rst__expandButton {
	transform: translate(50%, -50%);
}
.rst__collapseButton:focus,
.rst__expandButton:focus {
	outline: none;
	box-shadow: 0 0 0 1px #000, 0 0 1px 3px #83bef9;
}
.rst__collapseButton:hover:not(:active),
.rst__expandButton:hover:not(:active) {
	background-size: 24px;
	height: 20px;
	width: 20px;
}

.rst__collapseButton {
	background: $white
		url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48L2c+Cjwvc3ZnPg==')
		no-repeat center;
}

.rst__expandButton {
	background: $white
		url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48cGF0aCBkPSJNOSA0LjV2OSIvPjwvZz4KPC9zdmc+')
		no-repeat center;
}

/**
 * Line for under a node with children
 */
.rst__lineChildren {
	height: 100%;
	display: inline-block;
	position: absolute;
}
.rst__lineChildren::after {
	content: '';
	position: absolute;
	background-color: black;
	width: 0.05rem;
	left: 50%;
	bottom: 0;
	height: 10px;
}

@-moz-document url-prefix() {
	@media screen and (min-width: 2000px) {
		.rst__lineChildren::after {
			content: '';
			position: absolute;
			background-color: black;
			width: 0.07rem;
			left: 50%;
			bottom: 0;
			height: 10px;
		}
	}
}

.rst__rtl.rst__lineChildren::after {
	right: 50%;
	left: initial;
}

.rst__placeholder {
	position: relative;
	height: 68px;
	max-width: 300px;
	padding: 10px;
}
.rst__placeholder,
.rst__placeholder > * {
	box-sizing: border-box;
}
.rst__placeholder::before {
	border: 3px dashed $gray-200;
	content: '';
	position: absolute;
	top: 5px;
	right: 5px;
	bottom: 5px;
	left: 5px;
	z-index: -1;
}

/**
 * The outline of where the element will go if dropped, displayed while dragging
 */
.rst__placeholderLandingPad,
.rst__placeholderCancelPad {
	border: none !important;
	box-shadow: none !important;
	outline: none !important;
}
.rst__placeholderLandingPad *,
.rst__placeholderCancelPad * {
	opacity: 0 !important;
}
.rst__placeholderLandingPad::before,
.rst__placeholderCancelPad::before {
	background-color: lightblue;
	border-color: white;
}

/**
 * Alternate appearance of the landing pad when the dragged location is invalid
 */
.rst__placeholderCancelPad::before {
	background-color: #e6a8ad;
}

/* Collection default theme */

.ReactVirtualized__Collection {
}

.ReactVirtualized__Collection__innerScrollContainer {
}

/* Grid default theme */

.ReactVirtualized__Grid {
}

.ReactVirtualized__Grid__innerScrollContainer {
}

/* Table default theme */

.ReactVirtualized__Table {
}

.ReactVirtualized__Table__Grid {
}

.ReactVirtualized__Table__headerRow {
	font-weight: 700;
	text-transform: uppercase;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.ReactVirtualized__Table__row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.ReactVirtualized__Table__headerTruncatedText {
	display: inline-block;
	max-width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
	margin-right: 10px;
	min-width: 0px;
}
.ReactVirtualized__Table__rowColumn {
	text-overflow: ellipsis;
	white-space: nowrap;
}

.ReactVirtualized__Table__headerColumn:first-of-type,
.ReactVirtualized__Table__rowColumn:first-of-type {
	margin-left: 10px;
}
.ReactVirtualized__Table__sortableHeaderColumn {
	cursor: pointer;
}

.ReactVirtualized__Table__sortableHeaderIconContainer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.ReactVirtualized__Table__sortableHeaderIcon {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 24px;
	flex: 0 0 24px;
	height: 1em;
	width: 1em;
	fill: currentColor;
}

/* List default theme */

.ReactVirtualized__List {
}

/**
Custom
**/
.rst__rowToolbar {
	visibility: hidden;
	align-items: center;
	width: 0px;

	.rst__rowExtend:hover & {
		visibility: visible;
		width: auto;
	}
}
.rst__rowExtend {
	visibility: visible;
	display: flex;
	flex-wrap: nowrap;
}
.rst__rowExtend:hover {
	visibility: hidden;
	margin-left: -35px;
}

.rst__row {
	.rst--toggle & {
		box-shadow: 0 0 0 1px #000, 0 0 1px 3px #83bef9;
	}
}

.rst__languages {
	display: flex;
	flex-direction: column;
}

.rst__checkbox {
	height: 100%;
	width: 44px;
	background: #e9ecef;
	border: solid #aaa 1px;
	border-radius: 1px;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 8px;
}
