@import "./components/bootstrap4/functions";
@import "./components/bootstrap4/variables";
@import "./components/bootstrap4/mixins";
@import "./components/bootstrap4/root";
@import "./themes/default/variables";

$form-wizard-step-opacity: 1;

.article-content {
  iframe,
  img {
    max-width: 100%;
  }

  img {
    height: auto;
  }
}

/* user list pagination */
.dx-g-bs4-paging-panel {
  display: block !important;
}
.dx-g-bs4-table-container {
  cursor: pointer;
}

.flex-elem {
  display: flex;
  flex-wrap: wrap;
}

/* selectable list files */

.file-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}

.list-file-item {
  flex-direction: column;
  width: 75px;
  margin-left: 10px;
  min-height: 130px;

  .image {
    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #f8f9fa;
      border-top-left-radius: 0.3rem;
      border-top-right-radius: 0.3rem;
      border-bottom-right-radius: 0.3rem;
      border: 1px solid #dee2e6;
      max-height: 125px;
      min-height: 125px;
    }

    &-action {
      display: flex;
      justify-content: space-between;
    }

    &__content {
      cursor: pointer;
      align-items: center;
      justify-content: center;
      max-width: 100%;
      max-height: inherit;
      display: flex;
      margin: auto;
      font-size: 30px;
    }
  }
  .document {
    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #f8f9fa;
      border-radius: 0.3rem;
      border: 1px solid #dee2e6;
      max-height: 125px;
      min-height: 125px;
    }

    &-action {
      display: flex;
      justify-content: space-between;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 100%;
      max-height: inherit;
      padding: 20px;
    }

    &__name {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      text-align: center;
    }

    &__icon {
      font-size: 30px;
    }
  }
}

.image__removeable {
  margin-left: 25%;
}

.sortableHelper {
  z-index: 5000 !important;
}

/* TacCard Header */

.tag-card {
  &__header {
    cursor: pointer;
  }
  &__items {
    .rbt-token-removeable {
      width: auto;
    }
  }
}

/* User roles */
.user-roles {
  .rbt-token-removeable {
    width: auto;
  }
}

/* ThematicAreaCard Icon */

.thematic-icon {
  max-height: 125px;
  margin: auto;
  font-size: 125px;
  display: flex;
  justify-content: center;
}

/* Budget results */

.statistics {
  margin: 50px auto;
  flex-direction: column;

  .statistic {
    margin-bottom: 60px;
    .statistic__title {
      color: rgba(107, 123, 146, 1);
      font-weight: 600;
      font-size: 1.5rem;
      text-align: center;
    }

    .statistic__item {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      min-height: 200px;
      max-height: 200px;
      width: 100%;
    }
    .districts {
      padding: 0 10px;
    }
    &__items {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__value {
      border-radius: 8px;
      background-color: #39aae1;
      color: #fff;
      min-width: 100px;
      min-height: 60px;
      max-height: 60px;
      padding: 0 10px;
      position: absolute;
      top: 180px;
      text-align: center;
      line-height: 60px;
      font-size: 1.5rem;
      font-weight: 600;
    }

    &__icon {
      color: rgba(107, 123, 146, 1);
      font-size: 12.5rem;
    }

    &__chart {
      min-height: 400px;
      height: 400px;
      max-height: 400px;
      width: 100%;
    }
  }
}

.district,
.category,
.projects {
  margin: 20px 0;
}
.category__name {
  text-align: center;
}

.table-result,
.thead,
.tbody {
  display: flex;
  flex-direction: column;
}

.tr {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.tbody > .tr {
  border-top: solid 1px #f7f8f9;
}
.tbody > .tr:last-of-type {
  border-bottom: solid 1px #f7f8f9;
}
.td {
  display: flex;
  /* flex-grow: 1; */
}
.td-width-5 {
  flex-basis: 5%;
}
.td-width-10 {
  flex-basis: 10%;
}
.td-width-20 {
  flex-basis: 20%;
}
.td-width-100 {
  flex-basis: 100%;
}
.td-align-left {
  justify-content: flex-end;
}

.flex-basis-10 {
  flex-basis: 10% !important;
}

.flex-basis-20 {
  flex-basis: 20% !important;
}

.flex-basis-40 {
  flex-basis: 40% !important;
}

.flex-basis-50 {
  flex-basis: 50% !important;
}

.consultation-form--hidden,
.accordion-card--hidden {
  display: none;
}

.widget-content {
  .widget-content-wrapper {
    .widget-content-left {
      .widget-subheading {
        text-align: justify;
        i {
          opacity: 0.9;
        }
        &.consultation-form__short-description {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 300px;
        }
      }
    }
  }
}

.category-boxtarget {
  min-height: 150px;
  display: block;
}
.category-boxtarget.height--auto.dropzone-wrapper.dropzone-wrapper-lg {
  min-height: 300px;
  height: auto;
}
.category-boxtarget > div.rbt-token {
  width: initial;
  height: initial;
  display: inline-block;
  &-removeable {
    display: block;
    height: 100%;
    overflow: auto;
  }
}

.rbt {
  &-token {
    &-removeable {
      width: 100%;
      height: 100%;
      overflow: auto;
    }
  }
}

.width-100,
.modal-title {
  width: 100%;
}

.article__status {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1680px) {
  .article__status {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 1420px) {
  .form-wizard-step-done span,
  .form-wizard-step-todo span {
    display: none;
  }
  .forms-wizard li:before,
  .forms-wizard li:after {
    margin-top: -0.4rem;
  }
  .form-wizard-step-doing span {
    display: initial;
    margin-top: -0.4rem;
  }
}
@media (max-width: 360px) {
  .article__status .forms-wizard {
    display: flex;
    flex-direction: column;
  }
  .forms-wizard li:before,
  .forms-wizard li:after {
    margin-top: -1rem;
  }
  .article__status .forms-wizard span {
    display: inline;
  }
}

.animate-rotate-45 {
  -webkit-animation-name: spin;
  -webkit-animation-duration: 300ms;
  // -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;

  -moz-animation-name: spin;
  -moz-animation-duration: 300ms;
  // -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -moz-animation-fill-mode: forwards;

  -ms-animation-name: spin;
  -ms-animation-duration: 300ms;
  // -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -ms-animation-fill-mode: forwards;

  animation-name: spin;
  animation-duration: 300ms;
  // animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;

  @-ms-keyframes spin {
    from {
      -ms-transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(90deg);
    }
  }
  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }
    to {
      -moz-transform: rotate(90deg);
    }
  }
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(90deg);
    }
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(90deg);
    }
  }
}

.btn-actions-pane-right,
.btn-actions-pane-left {
  &.btn-actions-cursor--pointer {
    cursor: pointer;
  }
}

.rst__toolbarButton > .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.rst__virtualScrollOverride {
  overflow: inherit !important;
}

.pe-7s-exlamation {
  &::before {
    content: "!";
  }
}
.fg-danger {
  color: $danger;
}
.fg-success {
  color: $success;
}

.scroll-area-500 {
  height: 500px;
  overflow-x: hidden;
}

/* TinyMCE */
.tox.tox-tinymce.tox-tinymce-inline.tox-tinymce--toolbar-sticky-off {
  z-index: 8;
}

.is-invalid-contrast {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.article__icon {
  height: 1rem;
  width: 1rem;
  margin: 0 auto;
  &--draft {
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJwZW5jaWwtYWx0IiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtcGVuY2lsLWFsdCBmYS13LTE2IiByb2xlPSJpbWciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDUxMiA1MTIiPjxwYXRoIGZpbGw9IiM0QjUzNTUiIGQ9Ik00OTcuOSAxNDIuMWwtNDYuMSA0Ni4xYy00LjcgNC43LTEyLjMgNC43LTE3IDBsLTExMS0xMTFjLTQuNy00LjctNC43LTEyLjMgMC0xN2w0Ni4xLTQ2LjFjMTguNy0xOC43IDQ5LjEtMTguNyA2Ny45IDBsNjAuMSA2MC4xYzE4LjggMTguNyAxOC44IDQ5LjEgMCA2Ny45ek0yODQuMiA5OS44TDIxLjYgMzYyLjQuNCA0ODMuOWMtMi45IDE2LjQgMTEuNCAzMC42IDI3LjggMjcuOGwxMjEuNS0yMS4zIDI2Mi42LTI2Mi42YzQuNy00LjcgNC43LTEyLjMgMC0xN2wtMTExLTExMWMtNC44LTQuNy0xMi40LTQuNy0xNy4xIDB6TTEyNC4xIDMzOS45Yy01LjUtNS41LTUuNS0xNC4zIDAtMTkuOGwxNTQtMTU0YzUuNS01LjUgMTQuMy01LjUgMTkuOCAwczUuNSAxNC4zIDAgMTkuOGwtMTU0IDE1NGMtNS41IDUuNS0xNC4zIDUuNS0xOS44IDB6TTg4IDQyNGg0OHYzNi4zbC02NC41IDExLjMtMzEuMS0zMS4xTDUxLjcgMzc2SDg4djQ4eiI+PC9wYXRoPjwvc3ZnPg==");
  }
  &--confirmed {
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJ0aHVtYnMtdXAiIGNsYXNzPSJzdmctaW5saW5lLS1mYSBmYS10aHVtYnMtdXAgZmEtdy0xNiIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjNEI1MzU1IiBkPSJNMTA0IDIyNEgyNGMtMTMuMjU1IDAtMjQgMTAuNzQ1LTI0IDI0djI0MGMwIDEzLjI1NSAxMC43NDUgMjQgMjQgMjRoODBjMTMuMjU1IDAgMjQtMTAuNzQ1IDI0LTI0VjI0OGMwLTEzLjI1NS0xMC43NDUtMjQtMjQtMjR6TTY0IDQ3MmMtMTMuMjU1IDAtMjQtMTAuNzQ1LTI0LTI0czEwLjc0NS0yNCAyNC0yNCAyNCAxMC43NDUgMjQgMjQtMTAuNzQ1IDI0LTI0IDI0ek0zODQgODEuNDUyYzAgNDIuNDE2LTI1Ljk3IDY2LjIwOC0zMy4yNzcgOTQuNTQ4aDEwMS43MjNjMzMuMzk3IDAgNTkuMzk3IDI3Ljc0NiA1OS41NTMgNTguMDk4LjA4NCAxNy45MzgtNy41NDYgMzcuMjQ5LTE5LjQzOSA0OS4xOTdsLS4xMS4xMWM5LjgzNiAyMy4zMzcgOC4yMzcgNTYuMDM3LTkuMzA4IDc5LjQ2OSA4LjY4MSAyNS44OTUtLjA2OSA1Ny43MDQtMTYuMzgyIDc0Ljc1NyA0LjI5OCAxNy41OTggMi4yNDQgMzIuNTc1LTYuMTQ4IDQ0LjYzMkM0NDAuMjAyIDUxMS41ODcgMzg5LjYxNiA1MTIgMzQ2LjgzOSA1MTJsLTIuODQ1LS4wMDFjLTQ4LjI4Ny0uMDE3LTg3LjgwNi0xNy41OTgtMTE5LjU2LTMxLjcyNS0xNS45NTctNy4wOTktMzYuODIxLTE1Ljg4Ny01Mi42NTEtMTYuMTc4LTYuNTQtLjEyLTExLjc4My01LjQ1Ny0xMS43ODMtMTEuOTk4di0yMTMuNzdjMC0zLjIgMS4yODItNi4yNzEgMy41NTgtOC41MjEgMzkuNjE0LTM5LjE0NCA1Ni42NDgtODAuNTg3IDg5LjExNy0xMTMuMTExIDE0LjgwNC0xNC44MzIgMjAuMTg4LTM3LjIzNiAyNS4zOTMtNTguOTAyQzI4Mi41MTUgMzkuMjkzIDI5MS44MTcgMCAzMTIgMGMyNCAwIDcyIDggNzIgODEuNDUyeiI+PC9wYXRoPjwvc3ZnPg==");
  }
  &--toPublish {
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJmaWxlLWV4cG9ydCIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLWZpbGUtZXhwb3J0IGZhLXctMTgiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTc2IDUxMiI+PHBhdGggZmlsbD0iIzRCNTM1NSIgZD0iTTM4NCAxMjEuOWMwLTYuMy0yLjUtMTIuNC03LTE2LjlMMjc5LjEgN2MtNC41LTQuNS0xMC42LTctMTctN0gyNTZ2MTI4aDEyOHpNNTcxIDMwOGwtOTUuNy05Ni40Yy0xMC4xLTEwLjEtMjcuNC0zLTI3LjQgMTEuM1YyODhoLTY0djY0aDY0djY1LjJjMCAxNC4zIDE3LjMgMjEuNCAyNy40IDExLjNMNTcxIDMzMmM2LjYtNi42IDYuNi0xNy40IDAtMjR6bS0zNzkgMjh2LTMyYzAtOC44IDcuMi0xNiAxNi0xNmgxNzZWMTYwSDI0OGMtMTMuMiAwLTI0LTEwLjgtMjQtMjRWMEgyNEMxMC43IDAgMCAxMC43IDAgMjR2NDY0YzAgMTMuMyAxMC43IDI0IDI0IDI0aDMzNmMxMy4zIDAgMjQtMTAuNyAyNC0yNFYzNTJIMjA4Yy04LjggMC0xNi03LjItMTYtMTZ6Ij48L3BhdGg+PC9zdmc+");
  }
  &--published {
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJjaGVjay1jaXJjbGUiIGNsYXNzPSJzdmctaW5saW5lLS1mYSBmYS1jaGVjay1jaXJjbGUgZmEtdy0xNiIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjNEI1MzU1IiBkPSJNNTA0IDI1NmMwIDEzNi45NjctMTExLjAzMyAyNDgtMjQ4IDI0OFM4IDM5Mi45NjcgOCAyNTYgMTE5LjAzMyA4IDI1NiA4czI0OCAxMTEuMDMzIDI0OCAyNDh6TTIyNy4zMTQgMzg3LjMxNGwxODQtMTg0YzYuMjQ4LTYuMjQ4IDYuMjQ4LTE2LjM3OSAwLTIyLjYyN2wtMjIuNjI3LTIyLjYyN2MtNi4yNDgtNi4yNDktMTYuMzc5LTYuMjQ5LTIyLjYyOCAwTDIxNiAzMDguMTE4bC03MC4wNTktNzAuMDU5Yy02LjI0OC02LjI0OC0xNi4zNzktNi4yNDgtMjIuNjI4IDBsLTIyLjYyNyAyMi42MjdjLTYuMjQ4IDYuMjQ4LTYuMjQ4IDE2LjM3OSAwIDIyLjYyN2wxMDQgMTA0YzYuMjQ5IDYuMjQ5IDE2LjM3OSA2LjI0OSAyMi42MjguMDAxeiI+PC9wYXRoPjwvc3ZnPg==");
  }
  &--withdrawed {
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJhcnJvdy1jaXJjbGUtbGVmdCIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLWFycm93LWNpcmNsZS1sZWZ0IGZhLXctMTYiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHBhdGggZmlsbD0iIzRCNTM1NSIgZD0iTTI1NiA1MDRDMTE5IDUwNCA4IDM5MyA4IDI1NlMxMTkgOCAyNTYgOHMyNDggMTExIDI0OCAyNDgtMTExIDI0OC0yNDggMjQ4em0yOC45LTE0My42TDIwOS40IDI4OEgzOTJjMTMuMyAwIDI0LTEwLjcgMjQtMjR2LTE2YzAtMTMuMy0xMC43LTI0LTI0LTI0SDIwOS40bDc1LjUtNzIuNGM5LjctOS4zIDkuOS0yNC44LjQtMzQuM2wtMTEtMTAuOWMtOS40LTkuNC0yNC42LTkuNC0zMy45IDBMMTA3LjcgMjM5Yy05LjQgOS40LTkuNCAyNC42IDAgMzMuOWwxMzIuNyAxMzIuN2M5LjQgOS40IDI0LjYgOS40IDMzLjkgMGwxMS0xMC45YzkuNS05LjUgOS4zLTI1LS40LTM0LjN6Ij48L3BhdGg+PC9zdmc+");
  }
  &--archived {
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJhcmNoaXZlIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtYXJjaGl2ZSBmYS13LTE2IiByb2xlPSJpbWciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDUxMiA1MTIiPjxwYXRoIGZpbGw9IiM0QjUzNTUiIGQ9Ik0zMiA0NDhjMCAxNy43IDE0LjMgMzIgMzIgMzJoMzg0YzE3LjcgMCAzMi0xNC4zIDMyLTMyVjE2MEgzMnYyODh6bTE2MC0yMTJjMC02LjYgNS40LTEyIDEyLTEyaDEwNGM2LjYgMCAxMiA1LjQgMTIgMTJ2OGMwIDYuNi01LjQgMTItMTIgMTJIMjA0Yy02LjYgMC0xMi01LjQtMTItMTJ2LTh6TTQ4MCAzMkgzMkMxNC4zIDMyIDAgNDYuMyAwIDY0djQ4YzAgOC44IDcuMiAxNiAxNiAxNmg0ODBjOC44IDAgMTYtNy4yIDE2LTE2VjY0YzAtMTcuNy0xNC4zLTMyLTMyLTMyeiI+PC9wYXRoPjwvc3ZnPg==");
  }
}

.demo-bar {
  line-height: 3rem !important;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.forms-wizard {
  li {
    &.form-wizard-step-doing {
      &.status-draft {
        color: $gray-700;
        em {
          background: $alt;
          opacity: $form-wizard-step-opacity;

          &:hover {
            opacity: 1;
          }
        }

        &::before,
        &::after {
          background: $alt;
          opacity: $form-wizard-step-opacity;

          &:hover {
            opacity: 1;
          }
        }
      }
      &.status-confirmed {
        color: $gray-700;
        em {
          background: $info;
          opacity: $form-wizard-step-opacity;

          &:hover {
            opacity: 1;
          }
        }

        &::before,
        &::after {
          background: $info;
          opacity: $form-wizard-step-opacity;

          &:hover {
            opacity: 1;
          }
        }
      }
      &.status-to-publish {
        color: $gray-700;
        em {
          background: $warning;
          opacity: $form-wizard-step-opacity;

          &:hover {
            opacity: 1;
          }
        }

        &::before,
        &::after {
          background: $warning;
          opacity: $form-wizard-step-opacity;

          &:hover {
            opacity: 1;
          }
        }
      }
      &.status-published {
        color: $gray-700;
        em {
          background: $success;
          opacity: $form-wizard-step-opacity;

          &:hover {
            opacity: 1;
          }
        }

        &::before,
        &::after {
          background: $success;
          opacity: $form-wizard-step-opacity;

          &:hover {
            opacity: 1;
          }
        }
      }
      &.status-withdrawed {
        color: $gray-700;
        em {
          background: $danger;
          opacity: $form-wizard-step-opacity;

          &:hover {
            opacity: 1;
          }
        }

        &::before,
        &::after {
          background: $danger;
          opacity: $form-wizard-step-opacity;

          &:hover {
            opacity: 1;
          }
        }
      }
      &.status-archived {
        color: $gray-700;
        em {
          background: $dark;
          opacity: $form-wizard-step-opacity;

          &:hover {
            opacity: 1;
          }
        }

        &::before,
        &::after {
          background: $dark;
          opacity: $form-wizard-step-opacity;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    &.form-wizard-step-done {
      &.status-draft {
        em {
          background: $alt;
        }

        &::after,
        &::before {
          background: $alt;
        }
      }
      &.status-confirmed {
        em {
          background: $info;
        }

        &::after,
        &::before {
          background: $info;
        }
      }
      &.status-to-publish {
        em {
          background: $warning;
        }

        &::after,
        &::before {
          background: $warning;
        }
      }
      &.status-published {
        em {
          background: $success;
        }

        &::after,
        &::before {
          background: $success;
        }
      }
      &.status-withdrawed {
        em {
          background: $danger;
        }

        &::after,
        &::before {
          background: $danger;
        }
      }
      &.status-archived {
        em {
          background: $dark;
        }

        &::after,
        &::before {
          background: $dark;
        }
      }
    }
  }
}

.card-border-danger {
  border-color: $danger;
}

.div--disabled {
  pointer-events: none;
  opacity: 0.5;
}

.page-header-shared {
  border-bottom: 1px solid #d2d2d2;
  height: 66px;
  position: relative;
  background-color: #ffff;
  .page-header-left {
    cursor: pointer;
    float: left;
    margin: 0px 0px 0px 25px;
    position: absolute;
    top: 32%;
    display: flex;
  }
  .page-header-left-arrow {
    height: 25px;
  }
  .page-header-left-text {
    padding-top: 2px;
    font-weight: bold;
    color: #111;
  }
  .page-header-right {
    float: right;
  }
  .page-header-logo {
    height: 65px;
  }
}

.warning-container {
  background-color: #ffff;
  display: flex;
  flex-flow: wrap column;
  height: calc(100vh - 100px);
  max-width: 100vw;
  padding-top: 25px;
  &__header {
    margin: 25px auto;
    max-width: 1200px;
    position: relative;

    .header {
      font-size: 1.9rem;
      font-weight: 900;
      text-align: center;
    }

    .subheader {
      font-size: 1rem;
      font-weight: 500;
      text-align: center;
    }

    .icon {
      color: #111;
      font-size: 7rem;
      font-weight: 900;
      left: -140px;
      position: absolute;
      top: -15px;
    }
  }
}

.overflow--auto {
  overflow: auto !important;
}

.multimedia {
  ::selection {
    background: transparent;
  }
}

input[type="time"].form-control {
  &::-webkit-calendar-picker-indicator {
    display: none;
  }
}

.application-image-card {
  height: calc(100% - 30px);
  min-height: 196px;
}

.svg {
  &-inline {
    &--fa {
      pointer-events: none;
    }
  }
}
